import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Col, Row } from "react-bootstrap"

const panoramagalerie = () => {
  return (
    <Layout>
      <SEO title="Beispiel 360 Grad Panoramen Galerie" />
      <h2>Beispiel 360 Grad Panoramen Galerie</h2>

      <Row
        className="text-center"
        style={{
          marginTop: `1rem`,
          fontSize: `0.6rem`,
        }}
      >
        <Col sm>
          <AniLink fade to="/panorama/messedemo"></AniLink>
          <AniLink
            cover
            to="/panorama/messedemo"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            <StaticImage
              src="../../../static/emmi_multi.jpg"
              alt="Begehbares Panorama"
              style={{
                border: `3px solid #eee`,
              }}
            />
          </AniLink>

          <AniLink
            cover
            to="/panorama/messedemo"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            Begehbare Panoramen
          </AniLink>
        </Col>

        <Col sm>
          <AniLink
            cover
            to="/panorama/telekom"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            <StaticImage
              src="../../../static/telekom.jpg"
              alt="Begehbares Panorama"
              style={{
                border: `3px solid #eee`,
              }}
            />
          </AniLink>

          <AniLink
            cover
            to="/panorama/telekom"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            Telekom Einzelpanorama
          </AniLink>
        </Col>

        <Col sm>
          <AniLink
            cover
            to="/panorama/samsung"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            <StaticImage
              src="../../../static/samsung.jpg"
              alt="Begehbares Panorama"
              style={{
                border: `3px solid #eee`,
              }}
            />
          </AniLink>

          <AniLink
            cover
            to="/panorama/samsung"
            direction="up"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            Samsung Einzelpanorama
          </AniLink>
        </Col>
      </Row>
    </Layout>
  )
}

export default panoramagalerie
